.dayscale-row {
    display: flex;
    flex-direction: row;
}

.dayscale-label {
    width: 20%;
}
.program-row {
    display: inline-flex;
    width: fit-content;
    height: fit-content;
    margin-right: auto;
    margin-left: auto;
}
.program-row-df {
    display: inline-flex;
    width: 100%;
    height: fit-content;
    margin: auto;
}
.program-typo-wrapper {
    margin-top: auto;
    margin-bottom: auto;
}
.program-textfield-wrapper {
    margin: auto 6pt;
}

.program-textfield {
    width: 50pt;
    text-align: center;
    margin-left: 6pt;
    margin-right: 6pt;
}

.custom-appointment {
    margin-left: 3pt;
}
.program-appointment {
    height: 100%;
    text-align: center;
}
.program-text-container {
    width: 100%;
}
.program-text-container-df {
    width: 100%;
    margin: auto;
}
.program-title-dont-fill {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    flex-grow: 1;
}
.program-vertical {

}
.program-title {
font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bold;
}

.program-title-bottom {
    margin-top: -5px;
}

.df-description-text-field {
    flex-grow: 1;
}
