.add-course-wrapper {
    border-style: groove;
    margin: 6pt;
    padding: 6pt;
    text-align: left;
    background: aliceblue;
}
.add-course-row {
    display: inline-flex;
    width: 100%;
}
.category-select {
    min-width: 90%;
    margin-right: 6pt;
}
.category-form{
    background: #FFFFFF;
}
.add-course-name {
    background: #FFFFFF;
}