.banner-wrapper {
    background: #CCD0F1;
    display: inline-flex;
    width: 100%;
    border-style: groove;
}

.banner-typo {
    margin-top: auto;
    margin-bottom: auto;
}
