.section-info {
    border-style: groove;
    text-align: left;
    padding: 6pt;
    margin: 3pt;
}

.section-row {
    margin: 3pt;
    display: inline-flex;
    width: 100%;
}
.time-row {
    margin: 3pt;
    width: fit-content;
}
.section-date {
    border-style: groove;
    margin: 3pt;
    width: fit-content;
}